import logo from './logo.svg';
// import './App.css';

import Main from './Main';


const vars = {
  siteName: 'HieroMETA',
  siteDescription: `Our IT consulting services, ranging from infrastructure enhancement and security to cloud computing and custom software development, are designed to deliver impactful business results. We excel in guiding enterprises through the digital world by uncovering and interpreting key data insights.`,
  siteDescriptionX: 'Our IT consulting services, ranging from infrastructure enhancement and security to cloud computing and custom software development, are designed to deliver impactful business results. We excel in guiding enterprises through the digital world by uncovering and interpreting key data insights.',
  siteDescriptionOld: 'We specialize in a range of IT consulting services designed to add value, encompassing everything from  implementing cloud computing solutions, crafting custom software development, and providing thorough managed IT services and support. ',
  heroCard: {
    title: 'HieroMETA',
    description: `Inspiration & Code: Pioneering Custom Software Development & IT Consultancy Services`,
    subDescription: `Unleashing the Power of Digital Innovation for Your Business`,
  },
  
  whyChooseUs: {
    mainBlubX: 'Listening to your needs is a critical component of our consulting process. First, we work to understand your business as well as your unique needs and challenges. Then, we move on to research & analysis and investigate the best solutions for your goals. We finish by providing in-depth recommendations that align with your business needs and objectives.',
    mainBlub: 'At HieroMeta, we\'re not just developers and analysts; we\'re architects of the future. Our team is a blend of passionate IT experts, each with a unique story and a common goal: to revolutionize your digital experience.',
    blocks: [
      {
        icon: 'bx bx-code-block',
        title: 'Custom Software Development',
        description: 'Our highly custom web development services are designed to streamline workflows, increase revenue, and enhance business operations from initial concept to final deployment.',
        delay: 100
      },
      // {
      //   icon: 'bx bx-cube-alt',
      //   title: 'Implementation & Deployment',
      //   description: 'Our thorough development approach encompasses a detailed plan for software implementation and deployment. We focus on evaluating your requirements to ensure an enriched user experience for your end-users.',
      //   delay: 200
      // },
      {
        icon: 'bx bx-code-alt',
        title: 'API Development',
        description: 'We specialize in creating robust, well-documented, and user-friendly APIs. These APIs facilitate seamless integrations and allow for the customization of existing software products.',
        delay: 300
      },
      {
        icon: 'bx bx-scatter-chart',
        title: 'Predictive Analytics',
        description: 'Utilizing cutting-edge AI algorithms and data-driven insights, we offer precise forecasts and practical recommendations. Our goal is to enhance performance, reduce risks, and uncover new growth opportunities.',
        delay: 400
      }
    ]
  },

  aboutCard: {
    head: 'Who are we?',
    subHead: `Our IT consulting services, ranging from infrastructure enhancement and security to cloud computing and custom software development, are designed to deliver impactful business results. We excel in guiding enterprises through the digital world by uncovering and interpreting key data insights.`,
    blocks: [
      {
        icon: 'bx bx-rocket',
        title: 'Tailoring Technology to Transform Your Enterprise',
        description: 'Crafting Bespoke Solutions for the Digital Age: We focus on delivering robust and innovative software solutions. From streamlining operations with tailored web development to enhancing user experiences through agile methodologies, our approach is all about crafting technology that transforms your business.',
        link: '#',
        delay: 0
      },
      {
        icon: 'bx bx-atom',
        title: 'Empowering Digital Innovation',
        description: 'At HieroMeta, we believe in empowering businesses through strategic innovation. Our services span across system integration, API development, and data-driven predictive analytics. We integrate AI to turn data into insights, creating predictive models that anticipate trends and set new standards in the industry.',
        link: '#',
        delay: 100
      },
      {
        icon: 'bx bx-bulb',
        title: 'Visionary Solutions for Modern Challenges',
        description: 'Our commitment is to offer visionary solutions that address modern business challenges. With advanced programming skills in JavaScript, TypeScript, Swift, and more, we build scalable and interoperable web, mobile, and desktop applications. Our expertise in system and database management ensures unparalleled efficiency and reliability in our solutions.',
        link: '#',
        delay: 200
      },
    ] 
  },

  contact: {
    title: `Reach out to us for any inquiries or to discuss how we can help your business thrive in the digital age. Let's collaborate to create something extraordinary.`,
  }

}


function Header() {

  // <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>-->

  return (
    <header id="header" className="d-flex align-items-center">
    <div className="container d-flex align-items-center justify-content-between">

      <div className="logo">
        <h1><a href="index.html">( ( ( {vars.siteName} ) ) )</a></h1>
      </div>

      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a className="nav-link scrollto" href="#about">About</a></li>
          {/* <li><a className="nav-link scrollto" href="#services">Services</a></li>
          <li><a className="nav-link scrollto " href="#portfolio">Portfolio</a></li>
          <li><a className="nav-link scrollto" href="#team">Team</a></li>
          <li><a className="nav-link scrollto" href="#pricing">Pricing</a></li>
          <li><a href="blog.html">Blog</a></li> */}
          {/* <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li> */}
          <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>

    </div>
  </header>



    );
}


function Hero() {
  return (
    <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
      <div className="container" data-aos="fade-in">
        <h1>{vars.heroCard.title}</h1>
        <h2>
          {vars.heroCard.description}
          <br />
        </h2>
        <div className="d-flex align-items-center">
          <i className="bx bxs-right-arrow-alt get-started-icon"></i>
          <a href="#about" className="btn-get-started scrollto">Learn More</a>
        </div>
      </div>
    </section>
    );
}



function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">

            {/* <div className="col-lg-3 col-md-6 footer-contact">
              <h3>{vars.siteName}</h3>
              <p>
                A108 Adam Street <br />
                New York, NY 535022<br />
                United States <br /><br />
                <strong>Phone:</strong> +1 5589 55488 55<br />
                <strong>Email:</strong> info@example.com<br />
              </p>
            </div> */}

            {/* <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Services</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
              </ul>
            </div> */}
  
            {/* <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                <li><i className="bx bx-chevron-right"></i> <a href="#">Data Analytics</a></li>
              </ul>
            </div> */}
  
            {/* <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Join Our Newsletter</h4>
              <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
              <form action="" method="post">
                <input type="email" name="email" /><input type="submit" value="Subscribe" />
              </form>
            </div> */}


          </div>
        </div>
      </div>
      <div className="container d-lg-flex py-4">

      <div className="me-lg-auto text-center text-lg-start">
      <div className="copyright">
        &copy; 2024 Copyright <strong><span>{vars.siteName}</span></strong>. All Rights Reserved
      </div>
      {/* <div className="credits">
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div> */}
      </div>
      {/* <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
        <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
        <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
        <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
        <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
      </div> */}
      </div>
    </footer>
    )
}


function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Main vars={vars} />
      <Footer />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className='back-to-top d-flex align-items-center justify-content-center'><i className="bi bi-arrow-up-short"></i></a>
    </div>
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }


export default App;
